// **  Initial State
const initialState = {
  form: {},
  settings: {},
};

const affiliateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_VIP_SYSTEM_LIST":
      return {
        ...state,
        bonuses: payload,
      };
    case "GET_CURRENT_BONUS_INFO":
      return {
        ...state,
        form: payload,
      };
    case "GET_VIP_SYSTEM_SETTINGS":
      return {
        ...state,
        settings: payload,
      };
    default:
      return state;
  }
};

export default affiliateReducer;
